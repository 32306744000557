@font-face {
  /* stylelint-disable */
  font-family: 'TT Interphases Trl';
  font-weight: 400;
  /* stylelint-enable */
  src: url('/assets/fonts/tt-interphases-400.woff2') format('woff2');
  unicode-range:
    U+0-FF, U+131, U+152, U+153, U+2BB, U+2BC, U+2C6, U+2DA, U+2DC, U+400-45F,
    U+2000-206F, U+2074, U+20AC, U+20BD, U+2116, U+2122, U+2191, U+2193, U+2212,
    U+2215, U+FEFF, U+FFFD;
}

@font-face {
  /* stylelint-disable */
  font-family: 'TT Interphases Trl';
  font-weight: 700;
  /* stylelint-enable */
  src: url('/assets/fonts/tt-interphases-700.woff2') format('woff2');
  unicode-range:
    U+0-FF, U+131, U+152, U+153, U+2BB, U+2BC, U+2C6, U+2DA, U+2DC, U+400-45F,
    U+2000-206F, U+2074, U+20AC, U+20BD, U+2116, U+2122, U+2191, U+2193, U+2212,
    U+2215, U+FEFF, U+FFFD;
}

@font-face {
  /* stylelint-disable */
  font-family: 'TT Interphases Mono Trl';
  font-weight: 400;
  /* stylelint-enable */
  src: url('/assets/fonts/tt-interphases-mono-400.woff2') format('woff2');
  unicode-range:
    U+0-FF, U+131, U+152, U+153, U+2BB, U+2BC, U+2C6, U+2DA, U+2DC, U+400-45F,
    U+2000-206F, U+2074, U+20AC, U+20BD, U+2116, U+2122, U+2191, U+2193, U+2212,
    U+2215, U+FEFF, U+FFFD;
}

@font-face {
  /* stylelint-disable */
  font-family: 'TT Interphases Mono Trl';
  font-weight: 700;
  /* stylelint-enable */
  src: url('/assets/fonts/tt-interphases-mono-700.woff2') format('woff2');
  unicode-range:
    U+0-FF, U+131, U+152, U+153, U+2BB, U+2BC, U+2C6, U+2DA, U+2DC, U+400-45F,
    U+2000-206F, U+2074, U+20AC, U+20BD, U+2116, U+2122, U+2191, U+2193, U+2212,
    U+2215, U+FEFF, U+FFFD;
}

:root {
  // Normal
  --prlw-font-body: 400 14px/20px 'TT Interphases Trl', sans;
  --prlw-font-body-xl: 400 30px/36px 'TT Interphases Trl', sans;

  // TT Interphases Trl
  // Normal
  --prlw-font-common: 400 14px/20px 'TT Interphases Trl', sans;
  --prlw-font-common-xs: 400 10px/14px 'TT Interphases Trl', sans;
  --prlw-font-common-sm: 400 12px/16px 'TT Interphases Trl', sans;
  --prlw-font-common-md: 400 18px/24px 'TT Interphases Trl', sans;
  --prlw-font-common-lg: 400 20px/30px 'TT Interphases Trl', sans;
  --prlw-font-common-xl: 400 24px/30px 'TT Interphases Trl', sans;
  --prlw-font-common-xxl: 400 30px/30px 'TT Interphases Trl', sans;

  // Semi Bold
  --prlw-font-common-sm-sb: 600 12px/16px 'TT Interphases Trl', sans;
  --prlw-font-common-sb: 600 14px/20px 'TT Interphases Trl', sans;

  // Bold
  --prlw-font-common-b: 700 14px/20px 'TT Interphases Trl', sans;
  --prlw-font-common-md-b: 700 18px/24px 'TT Interphases Trl', sans;
  --prlw-font-common-lg-b: 700 20px/30px 'TT Interphases Trl', sans;
  --prlw-font-common-xl-b: 700 24px/30px 'TT Interphases Trl', sans;
  --prlw-font-common-xxl-b: 700 30px/30px 'TT Interphases Trl', sans;
  --prlw-font-common-xxxl-b: 700 50px/55px 'TT Interphases Trl', sans;

  // TT Interphases Mono Trl
  // Normal
  --prlw-font-common-mono: 400 14px/20px 'TT Interphases Mono Trl', sans;

  // Bold
  --prlw-font-common-mono-sm-b: 700 12px/20px 'TT Interphases Mono Trl', sans;

  // Buy License Module
  --prlw-sidebar-title: 400 20px/25px 'TT Interphases Trl', sans;
  --prlw-sidebar-text: 800 14px/20px 'TT Interphases Trl', sans;
  --prlw-color-card-day: 400 50px/50px 'TT Interphases Trl', sans;
  --prlw-color-card-day-text: 800 14px/20px 'TT Interphases Trl', sans;
  --prlw-color-card-price: 400 24px/28px 'TT Interphases Trl', sans;
  --prlw-color-card-percent: 800 14px/20px 'TT Interphases Trl', sans;
  --prlw-color-card-min: 400 11px/20px 'TT Interphases Trl', sans;
  --prlw-color-card-btn: 400 14px/20px 'TT Interphases Trl', sans;
  --prlw-color-card-discount: 400 20px/30px 'TT Interphases Trl', sans;
  --prlw-color-invoice-title: 400 50px/50px 'TT Interphases Trl', sans;
  --prlw-color-invoice-descr: 400 20px/30px 'TT Interphases Trl', sans;
  --prlw-color-invoice-tariff: 600 14px/20px 'TT Interphases Trl', sans;
  --prlw-color-invoice-change: 600 14px/20px 'TT Interphases Trl', sans;
  --prlw-color-invoice-input: 400 14px/20px 'TT Interphases Trl', sans;
  --prlw-color-invoice-info: 400 14px/20px 'TT Interphases Trl', sans;
  --prlw-color-invoice-doc-text: 400 20px/30px 'TT Interphases Trl', sans;
  --prlw-color-invoice-doc-tariff: 400 14px/20px 'TT Interphases Trl', sans;
  --prlw-color-invoice-doc-descr: 400 14px/20px 'TT Interphases Trl', sans;
  --prlw-color-invoice-doc-files: 800 14px/20px 'TT Interphases Trl', sans;
}
